<template>
  <div v-if="item.incompatibleItem">
    <div class="mx-015" :id="item.slug+'-incompatible-'+id">
      <!-- eslint-disable max-len -->
      <b-iconstack font-scale="1" style="color:#ff0000">
        <b-icon stacked icon="exclamation-triangle" scale=".9"></b-icon>
      </b-iconstack>
    </div>
    <b-tooltip :target="item.slug+'-incompatible-'+id">
        <strong>{{ $t('webmag.incompatibleTooltip') }}</strong>
    </b-tooltip>
  </div>
</template>

<script>
export default {
  name: 'IncompatipleIcon',
  props: ['id', 'item'],
};
</script>

<style scoped>

</style>
