var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item.incompatibleItem
    ? _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "mx-015",
              attrs: { id: _vm.item.slug + "-incompatible-" + _vm.id }
            },
            [
              _c(
                "b-iconstack",
                {
                  staticStyle: { color: "#ff0000" },
                  attrs: { "font-scale": "1" }
                },
                [
                  _c("b-icon", {
                    attrs: {
                      stacked: "",
                      icon: "exclamation-triangle",
                      scale: ".9"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-tooltip",
            { attrs: { target: _vm.item.slug + "-incompatible-" + _vm.id } },
            [
              _c("strong", [
                _vm._v(_vm._s(_vm.$t("webmag.incompatibleTooltip")))
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }